import { v4 as uuidv4 } from 'uuid';

import vsort1 from 'src/assets/img/assortment/vsort/1.jpg';
import vsort2 from 'src/assets/img/assortment/vsort/2.jpg';
import vsort3 from 'src/assets/img/assortment/vsort/3.jpg';
import vsort4 from 'src/assets/img/assortment/vsort/4.jpg';
import vsort5 from 'src/assets/img/assortment/vsort/5.jpg';
import vsortThumbnail from 'src/assets/img/assortment/vsort/vsortThumbnail.webp';

import firstSort1 from 'src/assets/img/assortment/1sort/1.jpg';
import firstSort2 from 'src/assets/img/assortment/1sort/2.jpg';
import firstSort3 from 'src/assets/img/assortment/1sort/3.jpg';
import firstSortThumbnail from 'src/assets/img/assortment/1sort/firstSortThumbnail.webp';

import secondSort1 from 'src/assets/img/assortment/2sort/1.jpg';
import secondSort2 from 'src/assets/img/assortment/2sort/2.jpg';
import secondSortThumbnail from 'src/assets/img/assortment/2sort/secondSortThumbnail.webp';

import manka1 from 'src/assets/img/assortment/manka/1.jpg';
import manka2 from 'src/assets/img/assortment/manka/2.jpg';
import manka3 from 'src/assets/img/assortment/manka/3.jpg';
import mankaThumbnail from 'src/assets/img/assortment/manka/mankaThumbnail.webp';

import otrubi1 from 'src/assets/img/assortment/otrubi/1.jpg';
import otrubi2 from 'src/assets/img/assortment/otrubi/2.jpg';
import otrubi4 from 'src/assets/img/assortment/otrubi/4.jpg';
import otrubiOThumbnail from 'src/assets/img/assortment/otrubi/otrubiOThumbnail.webp';
import otrubiThumbnail from 'src/assets/img/assortment/otrubi/otrubiThumbnail.webp';

const productsData = [
  {
    id: 'muka_pshenichnaya',
    title: 'Пшеничная мука',
    products: [
      {
        id: 'visshego_sorta_hlebopekarnaya',
        category: 'Пшеничная мука',
        shortCategory: 'Мука',
        title: 'Высшего Сорта хлебопекарная',
        saleSlogan: '☆ Выбор покупателей!',
        thumbnail: vsortThumbnail,
        volumes: [
          {
            id: uuidv4(),
            _id: '651284115a85cdd5295c1a7e',
            volume: 2,
            typeOfBag: 'бумажный пакет',
          },
          {
            id: uuidv4(),
            _id: '651285395a85cdd5295c1a7f',
            volume: 5,
            typeOfBag: 'полипропиленовый мешок',
          },
          {
            id: uuidv4(),
            _id: '651285395a85cdd5295c1a80',
            volume: 10,

            typeOfBag: 'полипропиленовый мешок',
          },
          {
            id: uuidv4(),
            _id: '651285395a85cdd5295c1a81',
            volume: 25,

            typeOfBag: 'полипропиленовый мешок',
          },
          {
            id: uuidv4(),
            _id: '651285395a85cdd5295c1a82',
            volume: 50,

            typeOfBag: 'полипропиленовый мешок',
          },
        ],
        photos: [
          {
            id: uuidv4(),
            link: vsort1,
          },
          {
            id: uuidv4(),
            link: vsort2,
          },
          {
            id: uuidv4(),
            link: vsort3,
          },
          {
            id: uuidv4(),
            link: vsort4,
          },
          {
            id: uuidv4(),
            link: vsort5,
          },
        ],
        specification:
          'Наша хлебопекарная мука высшего сорта — это продукт премиального качества, произведенный из отборного краснодарского зерна с использованием современного мукомольного оборудования. Мука обладает исключительными хлебопекарными характеристиками, обеспечивающими стабильно высокое качество выпечки. Оптимальное содержание и качество клейковины гарантируют превосходный объём изделий и формирование равномерной мелкопористой структуры мякиша.',
        suitable: [
          'сдоба',
          'кондитерские изделия',
          'песочное тесто',
          'дрожжевое тесто',
          'слоеное тесто',
        ],
        characteristics: [
          {
            id: uuidv4(),
            title: 'Белок',
            characteristic: '13-16%',
          },
          {
            id: uuidv4(),
            title: 'Сертификат',
            characteristic: 'EAC ГОСТ-26574-2017',
          },
          {
            id: uuidv4(),
            title: 'Белизна',
            characteristic: '52-56 едениц ',
          },
          {
            id: uuidv4(),
            title: 'Клейковина',
            characteristic: '27-30%',
          },
          {
            id: uuidv4(),
            title: 'ИДК',
            characteristic: '55-75 едениц',
          },
          {
            id: uuidv4(),
            title: 'Влажность',
            characteristic: '14-15%',
          },
          {
            id: uuidv4(),
            title: 'Сделано',
            characteristic: ' из мягких сортов пшеницы',
          },
          {
            id: uuidv4(),
            title: 'Срок хранения',
            characteristic: '12 месяцев',
          },
        ],
      },
      {
        id: 'pervogo_sorta_hlebopekarnaya',
        category: 'Пшеничная мука',
        shortCategory: 'Мука',
        title: 'Первого Сорта хлебопекарная',
        saleSlogan: 'Для лучшего хлеба.',
        thumbnail: firstSortThumbnail,

        volumes: [
          {
            id: uuidv4(),
            _id: '651285395a85cdd5295c1a83',
            volume: 25,
            typeOfBag: 'полипропиленовый мешок',
          },
          {
            id: uuidv4(),
            _id: '651285395a85cdd5295c1a84',
            volume: 50,
            typeOfBag: 'полипропиленовый мешок',
          },
        ],
        photos: [
          {
            id: uuidv4(),
            link: firstSort1,
          },
          {
            id: uuidv4(),
            link: firstSort2,
          },
          {
            id: uuidv4(),
            link: firstSort3,
          },
        ],
        specification:
          'Классическая хлебопекарная мука из отборного краснодарского зерна, обеспечивающая традиционный вкус и аромат выпечки. Оптимальный баланс клейковины и более темный оттенок муки придают выпечке традиционный вкус и аромат. Идеально подходит для производства классических хлебобулочных изделий.',
        suitable: [
          'несдобная выпечка',
          'пироги',
          'булки',
          'оладьи',
          'блины',
          'Классический хлеб',
        ],
        characteristics: [
          {
            id: uuidv4(),
            title: 'Белок',
            characteristic: '13-16%',
          },
          {
            id: uuidv4(),
            title: 'Сертификат',
            characteristic: 'EAC ГОСТ-26574-2017',
          },
          {
            id: uuidv4(),
            title: 'Белизна',
            characteristic: '44-46 едениц ',
          },
          {
            id: uuidv4(),
            title: 'Клейковина',
            characteristic: '26-29%',
          },
          {
            id: uuidv4(),
            title: 'ИДК',
            characteristic: '55-75 едениц',
          },
          {
            id: uuidv4(),
            title: 'Влажность',
            characteristic: '14-15%',
          },
          {
            id: uuidv4(),
            title: 'Сделано',
            characteristic: ' из мягких сортов пшеницы',
          },
          {
            id: uuidv4(),
            title: 'Срок хранения',
            characteristic: '12 месяцев',
          },
        ],
      },
      {
        id: 'vtorogo_sorta_hlebopekarnaya',
        category: 'Пшеничная мука',
        shortCategory: 'Мука',
        title: 'Второго Сорта хлебопекарная',
        saleSlogan: 'Для здоровья и пользы.',
        thumbnail: secondSortThumbnail,
        volumes: [
          {
            id: uuidv4(),
            _id: '651285395a85cdd5295c1a85',
            volume: 50,
            typeOfBag: 'полипропиленовый мешок',
          },
        ],
        photos: [
          {
            id: uuidv4(),
            link: secondSort1,
          },
          {
            id: uuidv4(),
            link: secondSort2,
          },
        ],
        specification:
          'Натуральная хлебопекарная мука с повышенным содержанием клетчатки. Благодаря особенностям помола сохраняет полезные свойства цельного зерна. Мука обладает выраженным сероватым оттенком и характерным зерновым ароматом. Хлеб из такой муки дольше сохраняет свежесть и имеет насыщенный вкус.',

        suitable: [
          'несдобная выпечка',
          'столовый хлеб',
          'печенье',
          'пряники',
          'бородинский хлеб',
        ],

        characteristics: [
          {
            id: uuidv4(),
            title: 'Белок',
            characteristic: '13-16%',
          },
          {
            id: uuidv4(),
            title: 'Сертификат',
            characteristic: 'EAC ГОСТ-26574-2017',
          },
          {
            id: uuidv4(),
            title: 'Белизна',
            characteristic: '12 едениц и ниже ',
          },
          {
            id: uuidv4(),
            title: 'Клейковина',
            characteristic: '26-29%',
          },
          {
            id: uuidv4(),
            title: 'ИДК',
            characteristic: '55-75 едениц',
          },
          {
            id: uuidv4(),
            title: 'Влажность',
            characteristic: '14-15%',
          },
          {
            id: uuidv4(),
            title: 'Сделано',
            characteristic: ' из мягких сортов пшеницы',
          },
          {
            id: uuidv4(),
            title: 'Срок хранения',
            characteristic: '12 месяцев',
          },
        ],
      },
    ],
  },
  {
    id: 'otrubi',
    title: 'Отруби',
    products: [
      {
        id: 'otrubi_kormovie',
        category: 'Пшеничные отруби',
        shortCategory: 'Отруби',
        title: 'Отруби Кормовые',
        saleSlogan: 'В наличии!',
        thumbnail: otrubiThumbnail,
        volumes: [
          {
            id: uuidv4(),
            _id: '651285395a85cdd5295c1a87',
            volume: 20,
            typeOfBag: 'полипропиленовый мешок',
          },
          {
            id: uuidv4(),
            _id: '651285395a85cdd5295c1a88',
            volume: 25,
            typeOfBag: 'полипропиленовый мешок',
          },
        ],
        photos: [
          {
            id: uuidv4(),
            link: otrubi4,
          },
          {
            id: uuidv4(),
            link: otrubi2,
          },
        ],
        specification:
          'Высококачественный побочный продукт мукомольного производства, богатый клетчаткой и питательными веществами. Производится на современном оборудовании из отборного краснодарского зерна. Отруби являются ценным источником клетчатки, витаминов группы B и минеральных веществ. Способствуют улучшению пищеварения и повышению продуктивности сельскохозяйственных животных.',

        suitable: ['Кормовые смеси', 'Комбикорма', 'Добавка к рациону'],
        characteristics: [
          {
            id: uuidv4(),
            title: 'Сертификат',
            characteristic: 'EAC ГОСТ 7169-2017',
          },
          {
            id: uuidv4(),
            title: 'Влажность',
            characteristic: '13.5 - 14.5',
          },
          {
            id: uuidv4(),
            title: 'Сделано',
            characteristic: ' из мягких сортов пшеницы',
          },
          {
            id: uuidv4(),
            title: 'Срок хранения',
            characteristic: 'до 12 месяцев',
          },
        ],
      },
      {
        id: 'otrubi_pischevie',
        category: 'Пшеничные отруби',
        shortCategory: 'Отруби',
        saleSlogan: 'В наличии!',
        thumbnail: otrubiOThumbnail,
        title: 'Отруби Очищенные',
        volumes: [
          {
            id: uuidv4(),
            _id: '651285395a85cdd5295c1a89',
            volume: 25,
            typeOfBag: 'полипропиленовый мешок',
          },
        ],
        photos: [
          {
            id: uuidv4(),
            link: otrubi1,
          },
          {
            id: uuidv4(),
            link: otrubi2,
          },

          {
            id: uuidv4(),
            link: otrubi4,
          },
        ],
        specification:
          'Продукт премиального качества, прошедший дополнительный этап очистки. Производится из отборного краснодарского зерна с использованием современных технологий. Благодаря дополнительной очистке продукт обладает улучшенными характеристиками и более высоким качеством. Богат клетчаткой, витаминами группы B и минералами.',

        suitable: [
          'злаковая выпечка',
          'диетические продукты',
          'отрубной хлеб',
          'каши',
        ],

        characteristics: [
          {
            id: uuidv4(),
            title: 'Сертификат',
            characteristic: 'EAC ГОСТ 7169-2017',
          },
          {
            id: uuidv4(),
            title: 'Влажность',
            characteristic: '13.5 - 14.5',
          },
          {
            id: uuidv4(),
            title: 'Сделано',
            characteristic: ' из мягких сортов пшеницы',
          },
          {
            id: uuidv4(),
            title: 'Срок хранения',
            characteristic: 'до 6 месяцев',
          },
        ],
      },
    ],
  },
  {
    id: 'krupa',
    title: 'Крупы',
    products: [
      {
        id: 'mannaya_krupa',
        category: 'Манка',
        shortCategory: 'Крупа',
        saleSlogan: 'В наличии!',
        thumbnail: mankaThumbnail,
        title: 'Манная крупа марка М',
        volumes: [
          {
            id: uuidv4(),
            _id: '651285395a85cdd5295c1a86',
            volume: '50',
            typeOfBag: 'полипропиленовый мешок',
          },
        ],
        photos: [
          {
            id: uuidv4(),
            link: manka1,
          },
          {
            id: uuidv4(),
            link: manka2,
          },
          {
            id: uuidv4(),
            link: manka3,
          },
        ],
        specification:
          'Классическая манная крупа из мягких сортов отборной краснодарской пшеницы. Производится на современном оборудовании с соблюдением всех технологических норм. Обладает однородной структурой и кремовым оттенком. Быстро разваривается, хорошо усваивается.',
        suitable: ['каши', 'мамалыги', 'манников', 'сырников', 'запеканки'],

        characteristics: [
          {
            id: uuidv4(),
            title: 'Сертификат',
            characteristic: 'EAC ГОСТ 7022-2019',
          },
          {
            id: uuidv4(),
            title: 'Влажность',
            characteristic: '14-15%',
          },
          {
            id: uuidv4(),
            title: 'зольность',
            characteristic: '0,46',
          },
          {
            id: uuidv4(),
            title: 'Сделано',
            characteristic: ' из мягких сортов пшеницы',
          },
          {
            id: uuidv4(),
            title: 'Срок хранения',
            characteristic: '7 месяцев',
          },
        ],
      },
    ],
  },
];

export default productsData;
